.page__header{
    background-color: white;
}

.page__main{
    padding: 3% 10%;
    text-align: center;
    color: black;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.page__mainContent h2{
    font-size: 4rem;
}

.page__mainContent p{
    font-size: 20px;
    color: rgb(126, 126, 126);
    line-height: 26px;
}



.linkIcon{
    margin-left: 20px;
}

.popular__devSection{
    padding: 3% 5%;
    background-color: whitesmoke;
    color: black;
}

.popular__devHeader{
    text-align: center;
}

.popular__devHeader h1{
    margin-bottom: 2rem;
    font-size: 2.8rem;
}

.popular__devCardWrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.popular__devCard{
    background-color: white;
    box-shadow: 0px 0.233px 7px lightgray;
    padding: 10px;
    border-radius: 6px;
    display: flex;
    margin: 1rem;
    width: 70%;
}

.popular__devCardImg{
    width: 20%;
}

.popular__devCardImg img{
    width: 100%;
    border-radius: 5px;
}

.popular__devCardText{
    width: 75%;
    padding: 15px;
    padding-top: 5px;
}

.popular__devCardText p{
    color:gray;
    line-height: 20px;
    font-size: 14px;
}

.mentor__skills{
    display: flex;
    flex-wrap: wrap;
}

.mentor__skillBadge{
    background-color: whitesmoke;
    
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 100px;
    text-transform: capitalize;
    padding: 6px 12px;
    font-weight: bold;
    font-size: 13px;
    

}

.btnWrapper{
    float: right;
    margin-top: 3rem;
}