.profileWrapper{
    color: rgb(34, 34, 34);
    position: absolute;
    top: 80px;
    left: auto;
    z-index: 5;
    padding: 1rem;
    width: 60%;
}

.profileCard{
    display: flex;
    flex-direction: column;
    
}

.profileInfo{
    margin-top: 1rem;
    
}

/* .profileInfo table{
    border: 1px solid var(--primary);
    border-radius: 5px;
    width: 100%;
}

.profileInfo table tr:nth-child(even){
    background-color: #1b2338;
}



.profileInfo table th,.profileInfo table td{
    padding:10px;
    text-align: left;
} */

.skillsWrapper{
    padding: 1rem;
    margin: 1rem;
    background-color: #1b2338;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.profileImg{
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

.formItem{
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
}

.socialLinkBtn{
    padding:10px 25px;
    border-radius: 5px;
    border: 1px dashed rgb(177, 176, 176);
    width: 93%;
    color: black;
    background-color: transparent;
    margin: 10px;
    margin-top: 20px;
    font-weight: bold;
  
}

.inputAdornment{
    display:flex;
    align-items:center;
}


.socialLinkBtn:hover{
    background-color: var(--primary);
    color: white;
    border-style: solid;
    cursor: pointer;
}

.closeIcon:hover{
    color:red;
    cursor: pointer;
}

.profileCard__header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.overlay{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    color: white;
    border-radius: 200px;
    transition: .3s ease;
    background-color: rgba(109, 109, 109, 0.37);
}

.overlay:hover {
    opacity: 1;
  }

  .uploadIcon {
    color: white;
    font-size: 40px;
    position: absolute;
    bottom: 10%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
  }



  .updateBtn{
      padding:10px 20px;
      margin:1rem;
      background:transparent;
      color: var(--primary);
      border: 2px solid var(--primary);
      cursor: pointer;
      font-weight: bold;

  }

  .updateBtn:hover{
      background-color: var(--primary);
      color: white;
  }

  .profileCard__header button,.profileImg button{
      box-shadow: 0px 0.234px 5px rgb(175, 175, 175);
  }

  .settings__wrapper,.settings__item{
      display: flex;
      flex-direction: column;
  }


  .settings__item{
      margin-bottom: 1rem;
  }

  .settings__item small{
      margin-bottom: 10px;
      color: gray;
  }

  .settings__item label{
      display: flex;
      align-items: flex-end;

  }

  .settings__item b{
      margin-left: 5px;
  }
  