
.main_wrapper{
    background-color: white;
    margin: 0;
    
    padding: 3rem;
    color: black;
}

.publicProfileWrapper{
    display: flex;
}

.logo__dot{
    font-size: 40px;
    color: var(--primary);
}


.publicUserDetails{
    margin: 1rem;
}

.publicUserInfo{
    margin: 1rem;
    width: 70%;
}

.publicUserInfo h1{
    margin: 0;
    margin-top: 1rem;
    margin-bottom: 10px;
}

.publicUserInfo p{
    color: gray;
    margin: 0;
    margin-bottom: 10px;
}

.publicUserInfo p b{
    font-size: 1.1rem;
}

.socialLinkArr{
    display: flex;
}

.socialLinkIcon{
    font-size: 3rem !important;
    background-color: rgb(228, 228, 228);
    padding: 5px;
    border-radius: 100px;
    margin-top: 1rem;
    margin-left: 0.7rem;
    margin-right: 0.7rem;
    color: rgb(34, 34, 34);
}

.socialLinkIcon:hover{
    color: black;
    background-color: lightgray;
}

.publicUserDetails img{
    width: 300px;
    height: 300px;
    object-fit: cover;
    border-radius: 500px;
}


.skillsWrapper{
    background-color: whitesmoke;
    padding: 1.5rem;
    box-sizing: border-box;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    border-radius: 10px;

}

.skillsWrapper h2{
   font-size: 2rem;

}

.skillsList{
    display: flex;
    flex-wrap: wrap;
}

.skillItem{
    background-color: white;
    box-shadow: 0px 0.234px 5px lightgray;
    padding: 10px 25px;
    border-radius: 5px;
    margin: 10px;
    font-weight: bold;

}

.sessions__wrapper{
    display: flex;
    flex-wrap: wrap;
    background-color: var(--primary);
    margin: 0;
    padding: 5% 3%;
    box-sizing: border-box;
    color: black;
}

.sessions__card{
    background-color: white;
    padding: 1.1rem;
    border-radius: 15px;
    width: 45%;
    margin: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0px 0.234px 12px rgb(59, 32, 58);

}

.sessions__card h3{
    font-size: 1.3rem;
}

.sessions__card p{
    color: gray;
    font-weight: bold;
    margin-top: 10px;
}

.sessions__cardHeader{
    display: flex;
   
}

.sessionLink{
    text-decoration: none;
    color: black;
}

.sessions__cardLinkIconWrapper{
    color: black;
    background-color: rgb(235, 235, 235);
    border-radius: 100px;
    padding: 10px ;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
}

.sessions__cardLinkIconWrapper:hover{
    background-color: lightgray;
}




.sessions__cardIcon{
    background-color: rgb(250, 250, 189);
    color: rgb(255, 217, 0);
    font-size: 3rem !important;
    border-radius: 100px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;

}

.topHeader{
    padding: 1rem 3rem;
    border-bottom: 1px solid lightgray;
    background-color: white;
    min-height: fit-content;

}

.logo__wrapper{
    width: 30%;
    display: flex;
    color: rgb(32, 32, 32);
    align-items: center;

}

.logo__wrapper img{
    width: 40px;
    margin-right: 10px;
}

.logo__wrapper h2{
    margin: 0;
}



.sessions__cardFooter{
    border-top: 1px solid lightgray;
    padding-top: 15px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

}

.sessions__cardPrice{
    float: right;
    background-color: rgb(241, 241, 241);
    padding: 10px 20px;
    border-radius: 100px;
    font-weight: bold;
}


.sessions__cardIconBlue{
    background-color: rgb(226, 248, 255);
    color: rgb(0, 140, 255);
}

.sessions__cardIconGreen{
    background-color: rgb(227, 255, 226);
    color: rgb(1, 194, 11);
}