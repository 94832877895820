.checkoutFormWrapper{
    display: flex;
    flex-wrap: wrap;
    
}

.checkoutInfo{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    color: white;
    width: 50%;
}

.checkoutInfo__img{
    margin-top: 5%;
    display: flex;
    align-items: center;
}

.checkoutInfo__img img{
    width: 70px;
}

.checkoutInfo__img h3{
    margin: 0;
    margin-left: 10px;
    font-size: 1.7rem;
}   



.checkoutForm__area{
    background-color: white;
    padding: 1rem;
    width: 50%;
    min-height:100vh;
}

.checkoutForm__area form{
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
}

.checkoutInfoCard{
    background-color: #151d31;
    padding: 1rem;
    border-radius: 10px;
    margin: 1rem;
    margin-top: 3rem;
    text-align: center;
    box-shadow: 0px 0.344px 7px #0d1320;

}

.checkoutInfoCard h4{
    border-bottom: 1px solid rgb(40, 35, 61);
    padding-bottom: 5px;
}

.checkoutInfoCard h2{
    font-size: 2rem;
}


.paymentSuccessCard{
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    padding: 1rem;
    padding-bottom: 2rem;
    color:white;
    background-color: rgb(31, 27, 49);
    box-shadow: #0d1320;
    border-radius: 8px;
    width: 40%;
    text-align: center;
}



.btn{
    color: white;
    text-decoration: none;
    padding: 10px 20px;
    background-color: var(--primary);
    margin: 1rem;
    border-radius: 3px;

}

.btn:hover{
    background-color: var(--darkPrimary);
    cursor: pointer;
}

.paymentSuccessCard h2{
    font-size: 2rem;
}

.paymentSuccessCardIcon{
    font-size: 5rem;
    color: rgb(3, 241, 122);
    margin: 1rem;
}