.bookingDetails__wrapper,.bookingAnswersWrapper{
    background-color: white;
    box-shadow: 0px 0.773px 15px rgb(233, 232, 232);
    padding: 1rem;
    border-radius: 10px;
    margin-top: 2rem;
    display:flex;
}

.bookingAnswersWrapper{
    flex-direction: column;
}


.bookingDetails__wrapper h2{
    font-size: 1.5rem;

}

.bookingDetails__col{
    width:fit-content;
}

.bookingDetails__dateBadge{
    background: whitesmoke;
    min-width:250px;
    height: 100%;
    border-radius:5px;
    padding:1rem;
    display: flex;
    flex-direction:column;
    align-items: center;
    justify-content: center;

}

.bookingDetails__dateBadge h2{
    margin: 0;
    margin-bottom: 5px;
    font-size: 1.8rem;
    color: rgb(151, 151, 151);
}

.bookingDetails__dateBadge h1{
    font-size: 4rem;
    margin: 0;
    color: black;

}

.bookingDetails__col2__footer{
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}

.bookingDetails__col2{
    padding: 1rem;
    padding-top: 0;
    width: 80%;
    margin-left: 1rem;
}

.bookingDetails__col2 h2{
    margin-bottom: 0;
}

.bookingDetails__col2__body{
    margin-top: 0.8rem;
}

.bookingDetails__col2Item,.bookingDetails__col2ItemIconWrapper{
    display: flex;
    align-items: center;
}

.bookingDetails__col2Item{
    margin-bottom: 15px;
}

.bookingDetails__col2Icon{
    margin-right: 10px;
    font-size: 1.4rem !important;
}

.bookingDetails__col2ItemIconWrapper{
    margin-right: 10px;
    color: black;
    font-weight: bold;
}



.bookingDetails__col2__header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}


