.dashboardNav a,.dashboardNav{
    display: flex;
    align-items: center;
    
}

.dashboardNav a{
    text-decoration: none;
    color: black;
    padding: 5px 15px;
}

.dashboardNav a:hover{
    background-color: whitesmoke;
    border-radius: 3px;
    transition: 0.3s ease-in-out;
}

.navIcon{
    margin-right: 7px;
}

.dashboardNav{
    margin-left: auto;
    margin-right: auto;
}




.logo__dot{
    font-size: 40px;
    color: var(--primary);
}

.dropdown__iconWrapper{
    background-color: rgb(188, 255, 188);
    padding: 5px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
   

}

.dropdown__icon{
    font-size: 20px;
}
