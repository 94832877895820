.tabsWrapper{
    display: flex;

}

.tab{
    border: 1px solid gray;
    border-radius: 100px;
    color: rgb(70, 69, 69);
    padding: 6px 20px;
    margin-left: 10px;
    margin-right: 10px;
    cursor: pointer;
    font-size: 15px;
    display: flex;
    align-items: center;
}

.tab:hover{
    background-color: rgb(241, 241, 241);
}

.card{
    background-color: white;
    border-radius: 5px;
    box-shadow: 0px 0.214px 5px lightgray;
    padding: 10px;
    margin:15px;
    margin-bottom: 1.3rem;

}

.timeBadge{
    background-color: rgb(235, 235, 235);
    border-radius: 5px;
    padding: 5px 10px;
    width: fit-content;

}

.activeTab{
    border-color: black;
    background-color: rgb(241, 241, 241);
    border-width: 2px;
    font-weight: bold;
}

.cardHeader{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 1rem;
}

.cardHeader h3{
    margin: 0;

}

.cardBtn{
    border: 1px dashed gray;
    border-radius: 100px;
    background-color: white;
    padding: 5px 10px;
    margin: 10px;


}

.cardBtn:hover{
    background-color: rgb(243, 242, 242);
    cursor: pointer;
   
}

.cardFooter{
    display: flex;
    align-items: center;
}

.cardIcon{
    font-size: 2rem !important;
    background-color: var(--primary);
    color: white;
    padding: 5px;
    border-radius: 100px;
    margin: 0;
    margin-top: 6px;
    margin-left: 10px;
}

.cardIcon:hover{
    background-color: var(--darkPrimary);
}

.cardHeader__right{
    display:flex;
    align-items: center;
}

.notAvailable{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: rgb(190, 190, 190);
    margin-top: 5%;
}

.notAvailable img{
    width: 100px;
}

.tableBtn{
    background-color: blueviolet;
    padding: 5px 10px;
    color: white;
    border: none;
    font-weight: bold;
    margin-left: 10px;
    border-radius: 3px;
}

.paymentStatus{
    display: flex;
}

.tableBtn:hover{
    background-color: rgb(90, 16, 160);
}