.link{
    display: flex;
    color: gray;
    text-decoration: none;
    align-items: center;
}

.link:hover{
    text-decoration: underline;
    color: var(--primary);
}

.top__header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.singleUserDetails{
    margin-top:1.5rem;
}