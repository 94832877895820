.blog__list{
    background-color: white;
    min-height: 100vh;
    color: black;
}


.blogs__cardGroup{
    display: flex;
    flex-wrap: wrap;
    padding: 3rem;
    padding-top: 10%;
    justify-content: center;
   

}

.blogDetails{
    padding: 3rem;
    padding-top: 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.blogDetails__img{
    width: 80%;
    border-radius: 10px;
    margin: 1rem;
    margin-bottom: 0;
    object-fit: cover;
}

.blogDetails__bottom{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.morePostLink{
    display: flex;
    text-decoration: none;
    color: blueviolet;
    align-items: center;
    padding: 5px 10px;
    transition: 0.25s;
    border-radius: 5px;
    width: fit-content;
}



.morePostLink:hover{
    background-color: whitesmoke;
}

.arrowRight{
    margin-left: 5px;
}


.blogDetails .authorImg{
    width: 50px;
    height: 50px;
}


.blogDetails__info{
    display: flex;
    align-items: center;
}

.blogDetails__info .createdDate span{
    color: rgb(43, 42, 42);
    margin-bottom: 5px;
}

.blog__overview{
    color: gray;
    text-align: center;
    text-transform: capitalize;
    width:80%;
    margin-left: auto;
    margin-right: auto;
}

.blog__content{
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1.5rem;
}


.blog__card{
    width: 45%;
    margin: 1rem;
}
.blog__card img{
    width: 95%;
    height: 300px;
    object-fit: cover;
    border-radius: 5px;
   
}

.blog__card img.blogCard__banner:hover{
    transform: scale(1.05);
    transition: 0.4s;
}

.blog__cardInfo{
    padding: 10px;
    padding-top: 20px;

}

.blog__author{
    display: flex;
    align-items: center;
}


img.authorImg{
    width: 30px;
    height: 30px;
    object-fit: cover;
    border-radius: 100px;
}

.blog__cardInfo p{
    color: gray;
    font-size: 13px;
    line-height: 20px;
}

.blog__author span{
    margin-left: 10px;
    font-size: 15px;
    color: gray;
}

.blog__card a{
    text-decoration: none;
    color: black;
}

.blog__card a:hover{
   transition: 0.3s;
   color: blueviolet;
}

.blog__cardFooter{
    display: flex;
    align-items: center;
}

.createdDate{
    color: gray;
    margin-left: 10px;

}

.cardTags{
    display: flex;
   margin-top: 10px;
}

.cardTags span{
    text-transform: capitalize;
    color: blueviolet;
    margin-left: 5px;
    margin-right: 5px;
}

.blog__cardsm{
    width: 30%;
}


.blog__authorAbout{
    background-color: rgb(243, 242, 242);
    padding: 15px;
    display: flex;
    border-radius: 7px;
    margin: 1rem;
    width: 45%;
}

.blog__authorArea{
    width: 20%;
}

.blog__authorTitle{
    margin-bottom: 10px;
}

.blog__authorArea img{
width: 100px;
height: 100px;
}

.blog__authorAboutInfo{
    width: 75%;
    margin-left: 2%;
}

.blog__authorAboutInfo p{
    color: gray;
    font-size: 15px;
}

@media screen and (max-width:600px){
    .blogs__cardGroup{
        flex-direction: column;

    }

    .blog__card{
        width: 100%;
    }

    .blog__cardsm{
        width: 100%;
    }
}