.contact__main{
    margin-top: 5rem;
    padding: 3rem;
    min-height: 100vh;
    display: flex;
    justify-content: space-between;
}

.contactForm{
    width:50%;
}

.contactFormItem{
    display: flex;
    flex-direction: column;
    margin: 1rem;
}

.contactFormItem label{
    margin-bottom: 10px;
}

.contactFormItem input,.contactForm textarea{
    box-shadow: 0px 0.223px 5px black;
    padding: 10px;
    border: none;
    border-radius: 5px;
}

.contact__info{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 80%;
    margin-top: 2rem;
}

.contact__infoItem{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: var(--primary);
    color: white;
    box-shadow: 0px 0.233px 5px rgb(27, 27, 27);
    padding: 1rem;
    border-radius: 5px;
    height: 200px;
    width: 200px;
    margin: 1rem;
    text-align: center;

}

.contact__infoIcon{
    font-size: 3.5rem !important;
    margin-bottom: 10px !important;
}

.contact__infoText{
    font-weight: bold;

}



.contactText{
   width: 50%;

}

