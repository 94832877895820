.landing__main{
    min-height:100vh;
    display: flex;
    justify-content: space-between;
    background: url('../../assets/landing2.jpg');
    background-size: cover;
    background-position: center;
}

.landing__main__left{
    margin-top:100px;
    padding:2rem;
    max-width:600px;
    box-sizing: border-box;
}

.landing__main__left h1{
    font-size:3.5rem;
    margin-bottom:10px;
    background-color: var(--primary);
    background-image: linear-gradient(45deg, var(--primary), var(--secondary));
    background-size: 100%;
    background-clip: text;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-text-fill-color: transparent;
    
}

.landing__main__left small{
    color:whitesmoke;
}

.btn__wrapper{
    margin-top:2.5rem;
}

.joinSection{
    padding: 2rem;
    padding-top: 4rem;
    min-height: 100vh;
}

.joinSection .joinSection__header h1{
    text-align: center;
    font-size: 3.5rem;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
}

.joinSection__header p{
    text-align: center;
    font-weight: bold;
    font-size: 1.5rem;
}

.joinSection__card{
    background-color: var(--darkPrimary);
    box-shadow: 0px 0.345px 8px black;
    padding: 2rem;
    margin: 1rem;
    border-radius: 10px;
    display: flex;

}

.joinSection__card img{
    width: 500px;
    
}

.joinSection__cardInfo{
    width:50%;
}

.joinSection__cardInfo h3{
    font-size: 1.8rem;
    margin: 0;
    margin-bottom: 10px;
}

.joinSection__cardInfo h2{
    font-size: 2.4rem;
    margin: 0;
    margin-bottom: 10px;
}

.joinSection__header{
    margin-bottom: 4rem;
}

.joinSection__card {
    margin-bottom: 5rem;
}


.careerCoach{
    min-height: 100vh;
    padding: 2rem;
    background-color: var(--darkBg);
    text-align: center;
    background-image: url("../../assets/geometricBg.png"), url("../../assets/geometricBg.png");
    background-repeat: no-repeat, no-repeat;
    background-position: left top, right bottom;
    background-size: 334px, 340px;
    
}

.careerCoach__header{
    margin-bottom: 4rem;
    margin-top: 4rem;
    width: 75%;
    margin-right: auto;
    margin-left: auto;
}

.careerCoach__header h1{
    font-size: 3rem;
    
}

.careerCoach__header p,.tryOutSection__header p{
    font-size: 1.2rem;
    line-height: 1.8rem;
    color: whitesmoke;
}


.careerCoach__cardWrapper{
    display: flex;
    justify-content: center;
}

.careerCoach__card{
    background-color: var(--bgColor);
    padding: 1.5rem;
    box-sizing: border-box;
    box-shadow: 0px 0.234px 5px rgb(19, 18, 18);
    border-radius: 10px;
    width: 30%;
    margin: 1rem;
}

.careerCoach__cardIcon{
    font-size: 8rem !important;
    
}

.careerCoach__cardBody h3{
    font-size: 1.6rem;
    margin-bottom: 0;
}

.careerCoach__cardBody p{
    font-size: 15px;
    color: whitesmoke;
}

.tryOutSection{
    padding: 2rem;
    text-align: center;
    min-height: 100vh;
    background-image: url("../../assets/codelinesbg.png"), url("../../assets/codelinesMore.png");
    background-repeat: no-repeat, no-repeat;
    background-position: left top, right bottom;
    background-size: 266px, 335px;
    padding-bottom: 4rem;
}

.tryOutSection__header{
    margin-top: 4rem;
    margin-bottom: 4rem;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
}


.tryOutSection__header h1{
    font-size: 3rem;
}

.tryOutSection__cardWrapper{
    display: flex;
    justify-content: center;
}

.tryOutSection__card{
    width: 30%;
    margin: 1rem;
    background-color: var(--darkBg);
    padding: 1rem;
    box-shadow: 0 0.234px 6px rgb(15, 14, 14);
    text-align: left;
    border-radius: 10px;
    padding-bottom: 1.8rem;
}

.tryOutSection__card h3{
    font-size: 1.5rem;
    text-align: center;
    border-bottom: 1px solid rgb(37, 36, 36);
    padding-bottom: 10px;
    margin: 0;
}

.tryOutSection__card p{
    line-height: 1.5rem;
    color: lightgray;
}

.tryOutSection__card p a{
    color: var(--primary);
}

.tryOutSection__card p a:hover{
    color: var(--darkPrimary);
}

.tryOutSection__cardFooter{
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
}


.services{
    display: flex;
    justify-content: space-between;
    padding: 7% 3%;
}

.services__heading{
    width: 40%;
}

.services__cardWrapper{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    width: 60%;

}

.service__card{
    display: flex;
    align-items: center;
    margin: 10px;
    margin-right: 5rem;
    position: relative;
    width: 250px;
}

.service__cardText{
    background-color: white;
    border: 1px solid black;
    color: black;
    padding: 18px 30px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    width: 100%;
    text-align: center;
    height: 100%;

}

.service__cardText h3{
    margin-left: 20px;
}

.service__cardIcon{
    background-color: rgb(253, 162, 241);
    padding: 8px;
    position: absolute;
    top: auto;
    left: -25px;
    border: 1px solid black;
    border-radius: 3px;


}

.service__cardIcon img{
    width: 40px;
}

.services__orange{
    background-color: rgb(255, 196, 0);
    color: orangered;
	font-size: 50px;
	text-shadow: -1px 1px 2px #000,
				  1px 1px 2px #000,
				  1px -1px 0 #000,
				  -1px -1px 0 #000;
    border-radius: 100px;
    padding: 10px 30px;
    text-align: center;
    text-transform: uppercase;
    transform: rotate(-5deg);
}

.services__white{
    background-color: white;
    color: white;
	font-size: 50px;
    width: fit-content;
	text-shadow: -1px 1px 2px #000,
				  1px 1px 2px #000,
				  1px -1px 0 #000,
				  -1px -1px 0 #000;
    border-radius: 100px;
    padding: 10px 30px;
    text-transform: uppercase;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
    transform: rotate(8deg);
}

.services__text{
    margin-left: auto;
    margin-right: auto;
    font-size: 50px;
    text-align: center;
    margin-top: 50px;
    
}


.useCases{
    display: flex;
    align-items: center;
    padding: 7% 3%;
    background-color: rgb(0, 160, 80);
    
}

.useCases__titleWrapper{
    width: 40%;
}

.useCases__title{
    display: flex;
    align-items: center;
    width: 400px;
    padding: 10px;
    position: relative;
}

.useCases__titleImg{
background-color: palevioletred;
padding: 5px;
border-radius: 4px;
border: 1px solid black;
position: absolute;
top: auto;
left: -25px;
}

.useCases__titleImg img{
    width: 70px;
}

.useCases__titleText{
    background-color: rgb(255, 208, 0);
    border: 1px solid black;
    border-radius: 5px;
    color: black;
    text-align: center;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 20px 30px;

}

.useCases__titleText h2{
   text-transform: uppercase;
   font-size: 40px;
   margin-left: 20px;
 
}


.useCases__listWrapper{
    width: 60%;
}

.useCases__list{
    display: flex;
    flex-direction: column;
}

.useCases__listItem{
    background-color: white;
    padding: 10px 20px;
    color: black;
    margin: 1rem;
    border: 1px solid black;
    border-radius: 100px;
    text-align: center;
    
}

.joinSection__cardInfo p{
    font-size: 15px;
    line-height: 20px;
}

@media screen and (max-width:600px){
    .landing__main{
        background-position: left;
    }

    .services{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .useCases{
        flex-direction: column;

    }

    .useCases__title{
        width: 250px;
    }

    .services__orange,.services__white{
        transform: rotate(0deg);
        font-size: 30px;
        width: fit-content;
        margin-left: auto;
        margin-right: auto;
    }

    

    .services__cardWrapper{
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .service__card{
        margin-right: 0;
    }

    .services__heading{
        width: 100%;
    }

    .joinSection .joinSection__header h1{
        font-size: 3rem;
        width: 100%;
    }

    .joinSection__header p{
        font-size: 1rem;
    }

    .joinSection__card{
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 1rem;
        margin: 1rem;
    }
    .joinSection__card img{
        width: 100%;
    }

    .joinSection__cardInfo{
        width: 100%;
    }

    .joinSection__cardInfo h3{
        font-size: 1.5rem;
    }

    .joinSection__cardInfo h2{
        font-size: 1.8rem;
    }

    

    .careerCoach__header p, .tryOutSection__header p{
        font-size: 1rem;
    }
   
    .tryOutSection__header{
        width: 100%;
    }

    .tryOutSection__header h1{
        font-size: 2.5rem;
    }

    .careerCoach__cardWrapper,.tryOutSection__cardWrapper{
        flex-direction: column;
        align-items: center;

    }

    .careerCoach__card,.tryOutSection__card{
        width: 100%;
    }
}

