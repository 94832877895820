.footer__wrapper{
    background-image: url("../../assets/codelinesbg.png"), url("../../assets/codelinesMore.png");
    background-repeat: no-repeat, no-repeat;
    background-position: left top, right bottom;
    background-size: 250px, 250px;
    padding:4rem 2rem;
    display:flex;
    justify-content: space-between;

}

.footer__about {
    width: 28%;
}

.footer__about img{
    width: 50px;
}

.footer__about__logo {
    text-decoration:none;
    color:white;
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}

.footer__about__logo h3{
    margin: 0;
    margin-left: 10px;
    font-size: 1.5rem;
}

.footer__about p{
    margin: 0;
    font-size: 14px;
    color: lightgray;
    margin-bottom: 1rem;
}

.footer__social{
    display: flex;
}

.footer__icon{
    color: var(--primary);
    font-size: 2rem;
    margin: 0px 10px;
}

.footer__about .footer__icon:hover{
    color: var(--darkPrimary);
}

.footer__links{
    margin: 0 1rem;
}

.footer__links h3{
   
    background-color: var(--primary);
    background-image: linear-gradient(45deg, var(--primary), var(--secondary));
    background-size: 100%;
    background-clip: text;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-text-fill-color: transparent;
}

.footer__links a{
    display: block;
    color: white;
    text-decoration: none;
    margin-bottom: 10px;
}

.footer__links a:hover{
    color: var(--primary);
  
}

.footer__contact{
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    margin-left: -12px;
}

.footer__light,.footer__light  a{
    color: rgb(44, 44, 44);
}

.footer__light .footer__about p{
    color: rgb(82, 81, 81);
}




.logo__dot{
    font-size: 40px;
    color: var(--primary);
}

@media screen and (max-width:600px){
    .footer__wrapper{
        flex-direction: column;
       
    }
    .footer__about{
        width: 100%;
        margin-bottom: 1.5rem;
        
    }

    .footer__links{
        margin-bottom: 1.2rem;
    }
    

    
}