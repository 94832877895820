.login__wrapper{
    display:flex;
    flex-wrap:wrap;
    min-height:100vh;
    background-image: url("../../assets/geometricBg.png"), url("../../assets/geometricBg.png");
    background-repeat: no-repeat, no-repeat;
    background-position: left top, right bottom;
    background-size: 334px, 340px;
}


.login__form{
    width:50%;
    padding:2rem;
    box-sizing:border-box;
}

.login__right{
    width:50%;
    background:var(--darkPrimary);
    background-image: url("../../assets/codelinesbg.png"), url("../../assets/codelinesMore.png");
    background-repeat: no-repeat, no-repeat;
    background-position: left top, right bottom;
    background-size: 334px, 340px;
    position:relative;
}

.login__rightBox{
    background:white;
    padding:10px 15px;
    box-shadow:0px 0.233px 10px #2e2e2d;
    position:absolute;
    top:70%;
    right:30%;
    width:fit-content;
    color:rgb(37, 37, 37);
    display:flex;
    border-radius:5px;
    align-items:center;
}

.company__info{
    margin-top:5rem;
    margin-left:auto;
    margin-right:auto;
    width:70%;
    text-align:center;
    color:white;
}

.company__info h2{
   font-size:2rem;
   margin-bottom:15px
}

.social__icons{
    display:flex;
    justify-content:center;
    margin-top:2rem;
}

.social__icons a{
    margin-left:10px;
    margin-right:10px;
    background:transparent;
    padding:5px;
    color:white;
    display:flex;
    align-items:center;
    width:fit-content;
    justify-content:center;
    margin:10px;
    border-radius:100px;
    box-shadow:0px 0.788px 5px #2f038f;
    
}

.social__icons a:hover{
    background:white;
    color: #2f038f;
    transition:0.3s ease-out;
}

.login__rightBox img{
    width:70px;
    height:70px;
    object-fit:cover;
    border-radius:200px;
    margin:10px;
}

.login__rightBox2{
    top:55%;
    right:0%;
}

.login__form{
    padding:10px;
    margin-top:5rem;
    width:35%;
    margin-left:auto;
    margin-right:auto;

}

.login__form h3{
    font-size:2rem;
    margin-top:2rem;
    margin-bottom:15px;
}

.login__form img.formLogo{
    width:40px;
    margin-right:10px;
}

.login__logoWrapper{
    display:flex;
    align-items:center;
    justify-content:center;
   
}

.form__el{
    margin-bottom:1rem;
    margin-top:1rem;
}

.form__el a{
    color:var(--primary);
    font-weight:bold;
}

.form__el a:hover{
    color:var(--darkPrimary);
}

.form__el label{
    color:white;
}

.form__el fieldset{
    color: white;
    border-color: white;
}



.form_el input,.form_el input::placeholder{
    color: white !important;
}






.googleLoginWrapper{
    border:1px solid lightgray;
    border-radius:5px;
    display:flex;
    align-items:center;
    justify-content:center;
    padding: 7px 10px;
}

.googleLoginWrapper:hover{
    background:whitesmoke;
    cursor:pointer;
    color:var(--primary);
}

.googleLoginWrapper h4{
    margin-bottom: 0;
}

.googleLoginWrapper img{
    width:35px;
    margin-right:15px;
}

.logo__dot{
    font-size: 40px;
    color: var(--primary);
}


@media screen and (max-width:600px){
    .login__wrapper{
        flex-direction: column;
    }

    .login__form{
        width: 90%;
    }
    .login__right{
        display: none;
    }
}