.contactUs{
    padding:2rem;
    background: var(--primary);
    text-align: center;

}

.contactUs h1{
    margin-top: 4rem;
    margin-bottom: 3rem;
    font-size: 3rem;
}

.contactForm{
    display: flex;
    flex-direction: column;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    justify-content: center;
}

.contactFormItem{
    padding: 10px 15px;
    margin: 10px;
    color: white;
    background-color: transparent;
    border: none;
    border-bottom: 2px solid white;
    font-size: 1.2rem;
}

.contactFormItem::placeholder{
    color: white;
    font-family: 'Arial',sans-serif;
    
}

.contactFormItem__inline{
    display: flex;
    margin-bottom: 2rem;
    width: 100%;
}

.contactFormItem__inline input{
    flex: 1;
}

.contactFormItem__inline input:first-child{
    margin-left: 0;
}

.contactFormItem__inline input:last-child{
    margin-right: 0;
}

.contactFormItem:focus{
    outline: none;
}

textarea.contactFormItem{
    width: 100%;
}


@media screen and (max-width:600px){
    .contactFormItem__inline{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .contactFormItem{
        width: 100%;
    }

    .contactForm{
        width: 100%;
    }
}