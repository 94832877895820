.blogs__header{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
}

.blogs__header h2{
    margin-bottom: 0;
}

.add__btnWrapper a{
box-shadow: none;
border-radius: 5px;
display: flex;
align-items: center;
justify-content: space-between;
}

.add__btnWrapper a span{
    margin-right: 10px;
}


.notFound{
    margin-left: auto;
    margin-right: auto;
    margin-top: 3rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.notFound img{
    width: 200px;
    margin-bottom: 1rem;
    opacity: 0.5;
}

.notFound h3{
    font-size: 1.4rem;
    color: rgb(194, 192, 192);
}


.blog__card{
    max-width:300px;
    margin: 1rem;
    padding: 5px;
    box-shadow: 0px 0.243px 5px lightgray;
    border-radius: 3px;
}

.blog__card img{
    width: 100%;
    height: 45%;
    border-radius: 4px;
    object-fit: cover;
    
}



.blog__card h5{
    font-size: 17px;
    
}

.blog__cardBody{
    padding: 10px;
}


.blog__cardBody p{
    font-size: 14px;
    color: gray;
    text-align: justify;
}


.card__footer{
    display:flex;
    align-items: flex-end;
    justify-content: flex-end;
    padding-bottom: 1.5rem;
}

.card__footerItem{
    margin: 5px;
    margin-left: 10px;
    margin-right: 10px;
    padding: 5px;
    border-radius: 4px;
    color: rgb(13, 32, 202);
}

.card__footerItem:hover{
    background-color: whitesmoke;
    cursor: pointer;
}

.card__footerIcon{
    font-size: 1.5rem;
}


.blogs__cardGroup{
    display: flex;
    flex-wrap: wrap;
}


.blog__formHeader{
    display: flex;
    align-items:center;
    justify-content: space-between;
}