.bookingWrapper{
    background-color: white;
    border-radius: 2rem;
    color: black;
    margin: 2%;
    margin-top: 3%;
    display: flex;
    flex-wrap: wrap;
}

.bookingDetails{
    width: 50%;
    border-right: 1px solid lightgray;
    
}

.bookingSetup{
    width: 50%;
}

.bookingTopHeader{
    display: flex;
    align-items: center;
    padding: 1rem;
    border-bottom: 1px solid lightgray;
   

}

.bookingSetupHeader{
padding: 1.3rem;
display: flex;
border-bottom: 1px solid lightgray;
}

.bookingDetails__body{
    padding: 1rem;
}

.bookingDetails__card{
    border: 1px solid lightgray;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
}

.bookingDetails__card h3{
    background-color: rgb(240, 235, 235);
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    padding: 1rem;
    height: 100px;
    font-size: 1.7rem;
    display: flex;
    align-items: center;
}

.bookingDetails__cardBody{
    display: flex;
    justify-content: space-between;
    padding: 1rem;
 

}

.bookingDetails__cardBody h5{
    font-size: 1.2rem;
   
   
}

.bookingDetails__para{
    color:rgb(104, 103, 103);
    margin-top: 1rem;
}

.bookingTopHeader h2{
    margin: 0;
    margin-left: 10px;
}

.bookingTopImg{
    display: flex;
    align-items: center;
}

.bookingTopHeader a{
    color:black;
    margin-right: 10px;
    padding: 10px;
    font-size: 1.2rem;
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bookingTopHeader a:hover{
    background-color: rgb(236, 233, 233);
    border-radius: 100px;
    
}

.getConnected{
    border-top: 1px solid lightgray;
}

.getConnected__header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
}

.getConnected__header h1{
    margin: 0;
    
}

.other__sessions{
    background-color: rgb(236, 235, 235);
    padding: 1rem;
    margin-top: 1rem;
    display: flex;
    overflow-x: auto;
}

.other__sessionsCard{
    background-color: white;
    border-radius: 5px;
    padding: 15px;
    box-shadow: 0px 0.233px 5px lightgray;
    margin: 1rem;
    height: 150px;
    

}
.bookingSetup__body{
    padding: 1rem;
    padding-top: 2rem;
}

.bookingSetup__body h3{
    padding-left: 1rem;
}

.noSlots{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
}

.noSlotsIcon{
    margin-bottom: 10px;
    font-size: 50px;
}

.noSlots p{
    color: rgb(90, 90, 90);
}

.bookingDateItem,.bookingTimeItem{
    border: 2px solid lightgray;
    padding: 10px;
    margin: 10px;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
}

.bookingTimeItem{
    padding: 15px 20px;
}

.bookingTimeWrapper{
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
   

}

.bookTimeArea{
    max-height: 300px;
    margin-top: 20px;
    overflow:auto;
}


.bookingDateItem:hover,.bookingTimeItem:hover{
    background-color: whitesmoke;
}

.settings__item{
    margin-bottom: 1rem;
    width: 90%;
    padding-left: 1rem;
    margin-top: 1rem;
}

.settings__item label{
    display: flex;
    align-items: center;
    margin-bottom: 15px;

}

.settings__item label b{
    margin-left: 10px;
}



.activeDateItem,.activeTimeItem{
    border-color:black;

}

.activeTimeItem{
    font-weight: bold;
}

.bookingDateItem p {
    color: gray;
    margin: 0;
    margin-bottom: 5px;
    font-size: 13px;
}

.other__sessionsCard h3{
    font-size: 1rem;
}

.other__sessionsCard p{
    font-size: 13px;

}

.other__sessionsCardFooter{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.other__sessionsCardIcon{
    color: white;
    background-color: black;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    padding: 5px;
    width: 30px;
    height: 30px;
}

.other__sessionsCardIcon:hover{
    transform: rotate(45deg);
    transition: 0.3s;
    background-color: rgb(34, 34, 34);
}


.finalForm{
    background-color: white;
    max-width: 550px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 1rem;
    color: black;
    box-shadow: 0px 0.234px 10px rgb(19, 18, 19);
    margin-top: 5%;
    margin-bottom: 5%;
}

.finalForm__headerIcon{
   cursor: pointer;
    padding: 10px;
    border-radius: 100px;
    font-size: 45px !important;
}

.modalFormItem label{
    font-weight: bold;
    display: block;
}

.finalForm__headerIcon:hover{
    background-color: rgb(236, 233, 233);
}

.finalForm__header{
    display: flex;
    align-items: center;
    border-bottom: 1px solid lightgray;
    padding: 1rem;
}

.finalForm__headerImg{
    display: flex;
    align-items: center;
    margin-left: 10px;

}

.finalForm__body{
    padding: 1rem;
}

.finalForm__info{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.finalForm__infoBadge{
    background-color: rgb(238, 238, 238);
    border-radius: 100px;
    padding: 5px 20px;
    font-weight: bold;
   
}

.finalForm__info p{
    color: rgb(90, 89, 89);
    font-weight: bold;
}

.finalForm__headerImg h4{
    margin: 0;
    margin-left: 10px;
}

.finalForm__calender{
    display: flex;
    align-items: center;
    background-color: rgb(233, 233, 233);
    border: 2px solid var(--primary);
    padding: 10px;
    border-radius: 4px;
    color: var(--primary);
    font-weight: bold;
    justify-content: space-between;
    margin-top: 1rem;

}

.finalForm__calenderFirst{
    display: flex;
    align-items: center;
    

}

.finalForm__calender span{
  
    margin-left: 5px;
    margin-right: 5px;

}


.finalFormItem{
    display: flex;
    flex-direction: column;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}

.finalFormItem label{
    margin-bottom: 6px;
}

.finalFormItem span{
    color: red;
    margin-right: 3px;
}

.finalForm__footer{
    display: flex;
    border-top: 1px solid lightgray;
    justify-content: space-between;
    align-items: center;
    padding: 0.8rem;
}


.finalForm__footerText{
    font-weight: bold;
    font-size: 18px;
}
