.terms__container{
    padding: 3rem;
    margin-top: 5%;
    background-color: white;
    color: black;
}

.terms__container p,.terms__container li{
    line-height: 20px;
    font-size: 14px;
    letter-spacing: 1.8px;
}

.terms__container li{
    margin-bottom: 10px;
}

.terms__container h2{
    margin-top: 2rem;
}