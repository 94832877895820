.mentor__wrapper{
    margin-top: 5rem;
    padding: 3rem;
}

.mentor__searchWrapper .mentor__searchInput,.mentor__searchWrapper input{
    padding: 10px 20px;
    color:white;
    background-color: #19223b;
    border-color: #19223b;

}

.mentor__searchWrapper input{
    background-color: #19223b;
}

.mentor__categoryWrapper{
    display:flex;
    flex-wrap: wrap;
    margin-top: 1rem;
}

.mentor__category{
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
   margin: 0.7rem;
   width: 10%;
   cursor: pointer;
   border-radius: 5px;
   text-align: center;



}

.mentor__category:hover{
    background-color: #19223b;
}

.mentor__categoryIcon{
    font-size: 40px !important;
    margin-bottom: 5px;
}

.mentor__searchWrapper input::placeholder{
    color: rgb(221, 218, 218);
}

.mentor__categoryActive{
    background-color:#19223b;
    font-weight: bold;
    border: 2px solid var(--primary);
}

.mentor__cards{
    display: flex;
    flex-direction: column;
}

.mentor__card{
    border-radius: 10px;
    background-color: #19223b;
    box-shadow: 0px 0.2333px 8px rgb(26, 25, 25);
    padding: 1rem;
    margin: 1rem;
    display: flex;
    flex-wrap: wrap;
}

.mentor__cardImg{
    width: 20%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}


.mentor__categoryWrapper{
    border-bottom: 1px solid #19223b;
}

.mentor__cardInfo{
    width: 60%;
}

.mentor__cardAction{
    padding: 1rem;
    padding-top: 0;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
}

.mentor__cardInfo p{
    font-size: 14px;
    color: rgb(219, 217, 217);
}

.mentor__card h4{
    font-size: 0.9rem;
    margin-top: 1rem;
    
}



.notFound{
    margin-left: auto;
    margin-right: auto;
    margin-top: 3rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.notFound img{
    width: 200px;
    margin-bottom: 1rem;
    opacity: 0.5;
}

.notFound h3{
    font-size: 1.4rem;
    color: rgb(231, 231, 231);
}

.domainLabel{
    background: #212c4b;
    border-radius: 3px;
    padding: 5px 10px;
    margin: 0.5rem 0;
    text-align: center;
   text-transform: capitalize;
   width: fit-content;

   
}

.skillsWrapper{
    display: flex;
    flex-wrap: wrap;
}

.skillBadge{
    border: 1px solid var(--primary);
    padding: 5px 10px;
    border-radius: 3px;
    margin: 10px;
    text-transform: capitalize;
    font-weight: bold;
}