:root{
    --primary: #9340ff;
    --darkPrimary:#7333c7;
    --bgColor:#101728;
    --secondary:rgb(182, 23, 221);
    --darkSecondary:rgb(147, 7, 182);
    --foreTextColor:rgb(255, 255, 255);
    --darkBg:#13161f;
    --swiper-navigation-color: #000;
    
    

}





body{
    background-color: var(--bgColor);
    color: var(--foreTextColor);
}

.primaryBtn{
    margin:1rem;
    padding:0.7rem 3rem;
    background: var(--primary);
    background: linear-gradient(145deg, var(--primary) 31%, var(--secondary) 100%);
    color:white;
    text-decoration:none;
    border:none;
    border-radius:100px;
    font-size:0.9rem;
    transition:0.3s;
    box-shadow:0px 3px 10px rgb(8, 8, 8);
    cursor:pointer;
    font-weight:bold;
}

.primaryBtn:hover{
    background: var(--darkPrimary);
    background: linear-gradient(145deg, var(--darkPrimary) 31%, var(--darkSecondary) 100%);
    transition:0.3s ease-out;
}

.primaryBtn:disabled{
    background:lightgray;
    color:#707070;
    cursor:not-allowed;
    box-shadow:none;

}

.secondaryBtn{
    border:2px solid var(--primary);
    color:var(--primary);
    text-decoration:none;
    border-radius:100px;
    font-size:0.9rem;
    transition:0.3s;
    margin:1rem;
    padding:0.7rem 3rem;
    font-weight:bold;
    box-shadow:0px 3px 10px rgb(8, 8, 8);
    cursor:pointer;
}

.secondaryBtn:hover{
    background: var(--primary);
    background: linear-gradient(145deg, var(--primary) 31%, var(--secondary) 100%);
    color:white;
}

.unauthorized {
    text-align:center;
}

.unauthorized img{
    width:32rem;
    margin-left:auto;
    margin-right:auto;
    display:block;

}

.primaryText{
    background-color: var(--primary);
    background-image: linear-gradient(45deg, var(--primary), var(--secondary));
    background-size: 100%;
    background-clip: text;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-text-fill-color: transparent;
    
   
}

.primaryBtn__plain{
    box-shadow: none;
    border-radius: 5px;
    padding: 8px 20px;
}

.blogDetailsPage .ql-container.ql-snow { 
    border: none !important;
}
