.service__area{
    padding: 1rem;

}

.service__item{
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.service__item img{
    width: 180px;
    text-align: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
    opacity: 0.8;
}

.service__item h2{
    margin: 0;
    margin-top: 1rem;
    margin-bottom: 8px;
}

.service__item small{
    color: gray;
    font-size: 14px;
    margin-bottom: 10px;
    display: block;
}

.createSessionHeader{
    display: flex;
    align-items: center;
    border-bottom: 1px solid lightgray;
}

.createSessionHeader h1{
    margin-left: 20px;
}

.backIcon{
    font-size: 2.2rem;
    cursor: pointer;
}

.backIcon:hover{
    background-color: rgb(235, 235, 235);
    border-radius: 100px;
    padding: 5px;
}
.primaryBtn{
    background-color: var(--primary);
    display: flex;
    align-items: center;
    color: white;
    padding: 10px 20px;
    border: none;
    margin-left: auto;
    margin-right: auto;
    font-weight: bold;
    box-shadow: 0px 0.234px 5px lightgray;
    border-radius: 4px;
    font-size: 15px;
}

.primaryBtn:hover{
    background-color: var(--darkPrimary);
    cursor: pointer;
}

.primaryBtn span{
    margin-left: 10px;
}

.createSessionBody{
    padding: 1rem;
}

.sessionTypeWrapper{
    display: flex;
    flex-wrap: wrap;
}

.sessionType{
    border: 1px solid lightgray;
    padding: 5px 10px;
    border-radius: 10px;
    margin: 1rem;
    min-width: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.sessionTypeHeader{
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    justify-content: center;
    margin-top: 10px;
}

.sessionTypeHeader h3{
    font-size: 1rem;
    margin: 0;
}

.sessionTypeIcon{
    margin-right: 8px;
    font-size: 1.3rem;
}

.sessionType small{
    font-size: 13px;
    visibility: hidden;
}

.sessionType:hover small{
    visibility: visible;
}

.activeSessionType small{
    visibility: visible;
}

.sessionType:hover:not(.activeSessionType){
    background-color: whitesmoke;
    cursor: pointer;
}

.activeSessionType{
    border: 2px solid black;
 
}

.sessionFormItem{
    display: flex;
    flex-direction: column;
    max-width: 600px;
    margin-bottom: 1rem;
}

.sessionFormItem label{
    display: flex;
    font-weight: bold;
}

.sessionFormItemGroup{
    display: flex;
    align-items: center;
}

.sessionFormItem__priceWrapper{
    display: flex;
    flex-wrap: wrap;
}

.sessionFormItem__priceItem{
    border: 1px solid lightgray;
    padding: 8px 15px;
    border-radius: 5px;
    margin: 10px;
    
}

.sessionFormItem__priceItem:hover{
    background-color: rgb(231, 231, 231);
    cursor: pointer;
}

.activePrice{
    border: 2px solid black;
    font-weight: bold;
}


.sessionFormItemGroup .sessionFormItem{
    margin-right: 10px;
    width: 250px;
}

.sessionCard{
    border: 1px solid lightgray;
    border-radius: 10px;
    padding: 1.2rem;
    margin: 1rem;
    display: flex;
    flex-direction: column;
    max-width: 400px;
}


.sessionCard__header{
    display: flex;
    justify-content: space-between;
}

.sessionCard__iconWrapper{
    display:flex;
    align-items: center;
}

.sessionCard__icon{
    margin-right: 5px;
}

.sessionCard__info{
    color: rgb(82, 82, 82);
    font-size: 15px;
}

.sessionCard__footer{
    display: flex;
    margin-top: 10px;
}

.sessionCard__btn{
    text-decoration: none;
    color: black;
    background-color: rgb(240, 239, 239);
    padding: 10px 30px;
    margin: 10px;
    border-radius: 100px;
    
}

.sessionCard__btn:hover{
    background-color: lightgray;
    transition: 0.3s;
}

.sessions__topheader{
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.sessions__topheader .primaryBtn{
    width: fit-content;
    margin-right: 0;
}


