.about__header{
    min-height: 100vh;
    padding: 3rem;
    display: flex;
    align-items: center;
    margin-top: 5rem;
}


.about__headerText{
    width: 50%;
    align-self: flex-start;
}

.about__headerImgWrapper{
    width: 50%;
    position: relative;
    
}

.about__headerImgWrapper img{
    width:450px;
    margin: 1rem;
   
}

.about__headerImg1{
    margin-bottom: 0;
    position: absolute;
    top: -300px;
    left: 20px;
    
}



.about__headerImg2{
    float: right;
    border: 20px solid #101728;
    position: absolute;
    top: -120px;
    right: 0;
    z-index: 10;
    
    
}

.about__headerText h2{
    font-size: 2.5rem;
    margin-top: 0;
}

.our__mission{
    display: flex;
    align-items: flex-start;
    padding: 3rem;
    background-color: white;
    color: black;
    justify-content: space-between;
}

.our__mission img{
    width: 450px;

}

.our__missionText{
    padding: 1rem;
    margin-left: 2rem;
}

.our__missionText h2{
    font-size: 2.5rem;
}

.about__header p{
    color: rgb(218, 216, 216) !important;
    line-height: 26px !important;
}

.our__missionText p{
    color: rgb(78, 77, 77) !important;
    line-height: 26px !important;
}

.our__missionImgWrapper{
    position: relative;
}

.our__missionText{
    width: 60%;
}

.our__missionImgWrapper img:last-child{
    border: 10px solid white;
    position: absolute;
    top:220px;
    left: 30px;
    
}

.our__missionImgWrapper img:first-child{
    position: absolute;
    left: -70px;
    top: 0;
}



.about__headerText a{
margin-left: 0;
}


.our__missionImgWrapper{
margin-top: 3rem;
width: 40%;
}


@media screen and (max-width:600px){
    .about__headerText{
        width: 100%;
        order: 2;
    }
    .about__header{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .about__headerImgWrapper{
        display: flex;
        flex-direction: column;
        width: 100%;
        order: 1;
    }
    .about__headerImgWrapper img{
        width: 80%;
    }
    .about__headerImg1,.about__headerImg2{
        position: static;
        
    }

    .our__mission{
        flex-direction: column;
    }

    .our__missionText{
        width: 100%;
    }

    

    .our__missionImgWrapper{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .our__missionImgWrapper img{
        position: static !important;
        margin: 10px;
        width: 90%;
    }


}



.allTeam{
    padding: 3rem;

}

.allTeam:first-child .card{
    box-shadow: 0px 0.275px 15px black;
    background-color: #101728;
}

.allTeam > h1{
    text-align: center;
    font-size: 3rem;
}

.card{
    width: 300px;
    height: 400px;
    box-shadow: 0px 2px 5px lightgray;
    box-sizing: border-box;
    margin:2%;
    text-align: center;
    border-radius: 4px;
}

.card__group{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
}

.card__img{
    width: 100%;
    height: 300px;
    background-size: cover;
    background-position: top;
    filter:grayscale(100%)
}

.card__body{
    padding: 15px;

}


.training__stat__section{
    padding: 3%;
    text-align: center;
}

.companyCards{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.companyBadge{
    padding: 10px 20px;
    margin:1rem;

}

.card__body h4{
    font-weight: bold;
    font-size: 1.2rem;
}


.card__body small{
    color: gray;
}