.paymentDetails__card{
    display: flex;
    margin: 1rem;
    margin-top: 3rem;
    background-color: white;
    box-shadow: 0px 0.233px 8px lightgray;
    border-radius: 10px;
}

.paymentDetails__cardLeft{
    border: 1px dotted lightgray;
    background-color: whitesmoke;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 300px;
    min-height: 200px;
}

.paymentDetails__cardLeft h3{
    font-size: 3rem;
    margin: 0;
}

.paymentDetails__cardRight,.cardBtnWrapper{
    padding: 1rem;
}

.paymentDetails__cardRight{
    width: 50%;
}

.paymentDetails__item{
    margin-bottom: 0.7rem;
}

.cardBtnWrapper{
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    padding-top: 0;
}

