.dashboard{
    display:flex;
    
}

.dashboardItem{
    color: white;
    position: absolute;
    top: 80px;
    left: 25%;
    z-index: 5;
    padding: 1rem;
}

.dashboardCard__wrapper{
    display: flex;
    flex-wrap: wrap;
}

.dashboardItemCard{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    margin: 1rem;
    border-radius: 10px;
    min-width: 300px;
    min-height: 200px;
}

.dashboardItemCard h2{
    font-size: 4rem;
    margin: 0;
}

.userInfoSide{
    background-color: whitesmoke;
    color: black;
    padding: 10px 20px;
    border-radius: 5px;
    margin: 10px;
    width:100%;
    margin-left: -10px;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.userInfoSideIcon:hover{
    color:var(--primary);
    cursor: pointer;
}

.userInfoSide__text{
    margin-left: 10px;
}



