
.sidenav__link{
    text-decoration:none;
    color:black;
    margin-left:10px;
}

.sidenav__link:hover{
    color: white;
}

.menuItem:hover .menuIcon{
    color: white;
}

.activeIcon,.activeLinkText{
    color: white !important;
}



.menuItem:hover{
    background-color: var(--primary) !important;
    transition: 0.3s !important;
}

.active{
    background-color: var(--primary) !important;
    
}